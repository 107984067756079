ul {
  margin: 0;
}

dl {
  margin: 0;

  dt {
    font-weight: 600;
  }

  dd {
    margin: 0;
  }

  &.is-horizontal {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: auto;
    grid-gap: 1px 1ch;
  }
}

.list-group {
  --list-border-width: 1px;
  --list-padding: 1rem;
  overflow: hidden;
  padding: 0;
  $this: &;

  &#{$this}--borderless {
    --list-border-width: 0;
  }

  &#{$this}--in-panel {
    --list-padding: 1rem 1rem 1rem 1.25rem;
  }

  & > #{$this}-item {
    display: block;
    text-decoration: none;
    padding: var(--list-padding);
    border: var(--list-border-width) solid var(--control-color-border);
    color: var(--color-text);

    &:not(:last-child) {
      margin-bottom: -1px;
    }

    .panel > & {
      margin: -1px;
    }

    & > h4 {
      margin: 0 0 0.5rem 0;
    }

    & > p {
      margin: 0;
    }

    & > h4,
    & > h5 {
      color: var(--color-primary);
      font-weight: 600;
    }

    & > h5 {
      font-size: 1rem;
      margin: 0;
    }
  }

  @media (hover: hover) {
    &:not(#{$this}--no-hover) > #{$this}-item {
      &:hover {
        background: var(--control-color-background-hover);
      }
    }
  }
}
