.error-page {
  & > .container {
    margin-top: 50px;
  }

  .error-image {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100px;
      height: 100px;
    }
  }
}
