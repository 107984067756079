@supports (-webkit-appearance: none) {
  progress[value] {
    --color-value: var(--color-primary);

    -webkit-appearance: none;
    overflow: hidden;
    border-radius: var(--control-border-radius);

    &.progress-danger {
      --color-value: var(--color-danger);
    }

    &.progress-success {
      --color-value: var(--color-success);
    }

    &.progress-warning {
      --color-value: var(--color-warning);
    }

    // background
    &::-webkit-progress-bar {
      background-color: var(--control-color-border);
    }

    // bar color
    &::-webkit-progress-value {
      background-color: var(--color-value);
    }
  }
}

progress[value] {
  &.is-block {
    display: block;
    width: 100%;
  }
}
