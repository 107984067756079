input,
textarea,
select,
button {
  font-size: 1rem;
}

label[for] {
  cursor: pointer;
}

.form-group {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  label {
    display: block;
    margin-bottom: 0.25rem;
    font-weight: bold;
  }

  .form-control {
    display: block;
    width: 100%;
  }
}

input:not([type]),
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="text"],
input[type="search"],
input[type="file"],
select,
textarea {
  min-height: 2.25rem;
  padding: 0.5rem 1rem;
  color: var(--color-text);
  background-color: var(--control-color-background-normal);
  border: 1px solid var(--control-color-border);
  border-radius: var(--control-border-radius);
  transition:
    border-color 0.25s,
    box-shadow 0.25s;

  &:hover {
    border-color: r-lighten($color-primary, 30%);
  }

  &:focus {
    border-color: r-lighten($color-primary, 20%);
    outline: 0;
    box-shadow: 0 0 0 3px rgba($color-primary, 0.25);
  }

  &[disabled],
  &[readonly] {
    background-color: var(--control-color-background-locked);
  }

  &::placeholder {
    color: var(--color-muted);
  }
}

select {
  cursor: pointer;
  transition: var(--control-transition-time) all;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: var(--control-color-background-hover);
  }
}
