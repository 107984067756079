.noselect {
  user-select: none;
  cursor: default;
}

.pull-right {
  float: right;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 50%;
}

[hidden] {
  display: none !important;
}

:root {
  --color-background: #f6f7fb;
  --color-primary: #665eba;
  --color-alternative: #857ec8;
  --color-information: var(--color-primary);
  --color-danger: #e91e63;
  --color-success: #00796b;
  --color-warning: #ef6c00;
  --color-information-bg: #f0eff8;
  --color-danger-bg: #fde9ef;
  --color-success-bg: #e6f2f0;
  --color-warning-bg: #fdf0e6;
  --color-text: #19334d;
  --color-muted: #a3adb8;
  --color-link-normal: var(--color-primary);
  --color-link-active: #c639a3;
  --color-wallpaper-gradient-start: var(--color-primary);
  --color-wallpaper-gradient-end: #9971a8;
  --control-color-border: #d9d7ee;
  --control-color-background-normal: #fff;
  --control-color-background-locked: #f0eff8;
  --control-color-background-hover: #e8e7f5;
  --control-border-radius: 5px;
  --control-transition-time: .2s;
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

html {
  -webkit-tap-highlight-color: #0000;
  text-size-adjust: 100%;
  font-size: 16px;
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  margin: 0;
  line-height: 1.5;
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
}

body, input, textarea, button, select {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

a {
  text-decoration: none;
}

a, a:visited {
  color: var(--color-link-normal);
}

a:hover, a:focus, a:active {
  color: var(--color-link-active);
  text-decoration: underline;
  text-decoration-color: var(--color-link-active);
}

a, input[type="button"], input[type="submit"], button {
  cursor: pointer;
  outline: 0;
}

input[type="range"] {
  width: 100%;
  display: block;
}

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #2b293d;
    --color-primary: #8882c9;
    --color-text: #8885ad;
    --color-link-active: #dd88c8;
    --control-color-border: #4a476b;
    --control-color-background-normal: #201f2e;
    --control-color-background-locked: #2b293d;
    --control-color-background-hover: #2b293d;
    --color-information-bg: var(--color-primary);
    --color-danger-bg: #e91e63;
    --color-success-bg: #00796b;
    --color-warning-bg: #ef6c00;
    --color-information: #f0eff8;
    --color-danger: #fde9ef;
    --color-success: #e6f2f0;
    --color-warning: #fdf0e6;
  }
}

.alert {
  border: 1px solid var(--color-information);
  border-radius: var(--control-border-radius);
  background-color: var(--color-information-bg);
  color: var(--color-information);
  margin: 1rem 0;
  padding: 1rem;
}

.alert > * {
  color: inherit;
}

.alert p {
  margin: 0;
}

.alert.alert-success {
  border-color: var(--color-success);
  background-color: var(--color-success-bg);
  color: var(--color-success);
}

.alert.alert-warning {
  border-color: var(--color-warning);
  background-color: var(--color-warning-bg);
  color: var(--color-warning);
}

.alert.alert-danger {
  border-color: var(--color-danger);
  background-color: var(--color-danger-bg);
  color: var(--color-danger);
}

.btn {
  text-align: center;
  border: 1px solid var(--control-color-border);
  border-radius: var(--control-border-radius);
  background-color: var(--control-color-background-normal);
  margin: 0;
  padding: .5rem 1rem;
  display: inline-block;
}

.btn, .btn:hover, .btn:active, .btn.active, .btn:focus {
  transition: var(--control-transition-time) all;
  color: #665eba;
  outline: 0;
  text-decoration: none;
}

.btn:hover, .btn:focus, .btn:active, .btn.active {
  background-color: var(--control-color-background-hover);
}

.btn:active, .btn:focus {
  outline: 0;
  box-shadow: inset 0 1px 1px #0000001a, 0 0 0 3px #665eba40;
}

.btn.btn-primary {
  background-color: var(--color-primary);
  color: #fff;
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary.active {
  background-color: var(--color-alternative);
}

.btn.btn-block {
  width: 100%;
  margin: 0 0 .5rem;
  display: block;
}

.btn.btn-flat {
  color: var(--color-primary);
  transition: var(--control-transition-time) all;
  box-shadow: none;
  background: none;
  border: 0;
  border-radius: 4px;
  margin: 0 .1rem;
  padding: .5rem 1.25rem;
}

.btn.btn-flat, .btn.btn-flat:active, .btn.btn-flat:focus, .btn.btn-flat:hover {
  text-decoration: none;
}

.btn.btn-flat:focus, .btn.btn-flat:hover {
  background-color: var(--color-primary);
  color: #fff;
}

.btn.btn-small {
  padding: .1rem 1rem;
}

.btn.btn-large {
  padding: 1rem 3rem;
}

.btn.disabled, .btn[disabled] {
  opacity: .75;
  pointer-events: none;
}

input, textarea, select, button {
  font-size: 1rem;
}

label[for] {
  cursor: pointer;
}

.form-group:not(:last-child) {
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: .25rem;
  font-weight: bold;
  display: block;
}

.form-group .form-control {
  width: 100%;
  display: block;
}

input:not([type]), input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="url"], input[type="text"], input[type="search"], input[type="file"], select, textarea {
  color: var(--color-text);
  background-color: var(--control-color-background-normal);
  border: 1px solid var(--control-color-border);
  border-radius: var(--control-border-radius);
  min-height: 2.25rem;
  padding: .5rem 1rem;
  transition: border-color .25s, box-shadow .25s;
}

input:not([type]):hover, input[type="email"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="tel"]:hover, input[type="url"]:hover, input[type="text"]:hover, input[type="search"]:hover, input[type="file"]:hover, select:hover, textarea:hover {
  border-color: #948ecf;
}

input:not([type]):focus, input[type="email"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="url"]:focus, input[type="text"]:focus, input[type="search"]:focus, input[type="file"]:focus, select:focus, textarea:focus {
  border-color: #857ec8;
  outline: 0;
  box-shadow: 0 0 0 3px #665eba40;
}

input:not([type])[disabled], input:not([type])[readonly], input[type="email"][disabled], input[type="email"][readonly], input[type="number"][disabled], input[type="number"][readonly], input[type="password"][disabled], input[type="password"][readonly], input[type="tel"][disabled], input[type="tel"][readonly], input[type="url"][disabled], input[type="url"][readonly], input[type="text"][disabled], input[type="text"][readonly], input[type="search"][disabled], input[type="search"][readonly], input[type="file"][disabled], input[type="file"][readonly], select[disabled], select[readonly], textarea[disabled], textarea[readonly] {
  background-color: var(--control-color-background-locked);
}

input:not([type])::placeholder, input[type="email"]::placeholder, input[type="number"]::placeholder, input[type="password"]::placeholder, input[type="tel"]::placeholder, input[type="url"]::placeholder, input[type="text"]::placeholder, input[type="search"]::placeholder, input[type="file"]::placeholder, select::placeholder, textarea::placeholder {
  color: var(--color-muted);
}

select {
  cursor: pointer;
  transition: var(--control-transition-time) all;
}

select:hover, select:focus, select:active, select.active {
  background-color: var(--control-color-background-hover);
}

.error-page > .container {
  margin-top: 50px;
}

.error-page .error-image {
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-page .error-image svg {
  width: 100px;
  height: 100px;
}

.container {
  --container-width: 1080px;
  --container-padding: 1rem;
  width: min(var(--container-width), 100% - var(--container-padding));
  margin-left: auto;
  margin-right: auto;
}

.container.container-fluid {
  --container-width: 100%;
}

.container.container-narrow {
  --container-width: 560px;
}

.container.container--no-padding {
  --container-padding: 0px;
}

.grid {
  grid-gap: 1rem;
  grid-auto-rows: auto;
  display: grid;
}

.grid.grid--no-gap {
  grid-gap: 0;
}

.grid.grid-mobile--1 {
  grid-template-columns: 1fr;
}

.grid.grid-mobile--1-1 {
  grid-template-columns: 1fr 1fr;
}

.grid.grid-mobile--1-2 {
  grid-template-columns: 1fr 2fr;
}

.grid.grid-mobile--1-1-1 {
  grid-template-columns: 1fr 1fr 1fr;
}

.grid.grid-mobile--1-2-1 {
  grid-template-columns: 1fr 2fr 1fr;
}

.grid.grid-mobile--2-1 {
  grid-template-columns: 2fr 1fr;
}

@media screen and (width >= 720px) {
  .grid.grid-tablet--1 {
    grid-template-columns: 1fr;
  }

  .grid.grid-tablet--1-1 {
    grid-template-columns: 1fr 1fr;
  }

  .grid.grid-tablet--1-2 {
    grid-template-columns: 1fr 2fr;
  }

  .grid.grid-tablet--1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid.grid-tablet--1-2-1 {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .grid.grid-tablet--2-1 {
    grid-template-columns: 2fr 1fr;
  }
}

@media screen and (width >= 1080px) {
  .grid.grid--1 {
    grid-template-columns: 1fr;
  }

  .grid.grid--1-1 {
    grid-template-columns: 1fr 1fr;
  }

  .grid.grid--1-2 {
    grid-template-columns: 1fr 2fr;
  }

  .grid.grid--1-1-1 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid.grid--1-2-1 {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .grid.grid--2-1 {
    grid-template-columns: 2fr 1fr;
  }
}

@media screen and (width <= 719px) {
  .grid .hidden-mobile {
    display: none;
  }
}

@media screen and (width >= 720px) and (width <= 1079px) {
  .grid .hidden-tablet {
    display: none;
  }
}

@media screen and (width >= 1080px) {
  .grid .hidden-desktop {
    display: none;
  }
}

ul, dl {
  margin: 0;
}

dl dt {
  font-weight: 600;
}

dl dd {
  margin: 0;
}

dl.is-horizontal {
  grid-gap: 1px 1ch;
  grid-template-columns: 1fr 2fr;
  grid-auto-rows: auto;
  display: grid;
}

.list-group {
  --list-border-width: 1px;
  --list-padding: 1rem;
  padding: 0;
  overflow: hidden;
}

.list-group.list-group--borderless {
  --list-border-width: 0;
}

.list-group.list-group--in-panel {
  --list-padding: 1rem 1rem 1rem 1.25rem;
}

.list-group > .list-group-item {
  padding: var(--list-padding);
  border: var(--list-border-width) solid var(--control-color-border);
  color: var(--color-text);
  text-decoration: none;
  display: block;
}

.list-group > .list-group-item:not(:last-child) {
  margin-bottom: -1px;
}

.panel > .list-group > .list-group-item {
  margin: -1px;
}

.list-group > .list-group-item > h4 {
  margin: 0 0 .5rem;
}

.list-group > .list-group-item > p {
  margin: 0;
}

.list-group > .list-group-item > h4, .list-group > .list-group-item > h5 {
  color: var(--color-primary);
  font-weight: 600;
}

.list-group > .list-group-item > h5 {
  margin: 0;
  font-size: 1rem;
}

@media (hover: hover) {
  .list-group:not(.list-group--no-hover) > .list-group-item:hover {
    background: var(--control-color-background-hover);
  }
}

.panel {
  --panel-color-border: var(--control-color-border);
  --panel-color-text: var(--color-information);
  --panel-color-bg: transparent;
  background-color: var(--control-color-background-normal);
  border: 1px solid var(--panel-color-border);
  border-radius: var(--control-border-radius);
  margin-bottom: 1rem;
  overflow: hidden;
}

.panel > .panel-heading {
  color: var(--panel-color-text);
  background-color: var(--panel-color-bg);
  margin: 0;
  padding: .75rem 1.25rem;
  font-size: 1em;
  font-weight: 600;
}

.panel > .panel-body {
  padding: 1.25rem;
}

.panel > .panel-footer {
  background-color: var(--panel-color-bg);
  padding: .75rem 1.25rem;
}

.panel.panel-primary {
  --panel-color-border: var(--color-information);
  --panel-color-text: var(--color-information);
  --panel-color-bg: var(--color-information-bg);
}

.panel.panel-danger {
  --panel-color-border: var(--color-danger);
  --panel-color-text: var(--color-danger);
  --panel-color-bg: var(--color-danger-bg);
}

.panel.panel-success {
  --panel-color-border: var(--color-success);
  --panel-color-text: var(--color-success);
  --panel-color-bg: var(--color-success-bg);
}

.panel.panel-warning {
  --panel-color-border: var(--color-warning);
  --panel-color-text: var(--color-warning);
  --panel-color-bg: var(--color-warning-bg);
}

@supports ((-webkit-appearance: none)) {
  progress[value] {
    --color-value: var(--color-primary);
    -webkit-appearance: none;
    border-radius: var(--control-border-radius);
    overflow: hidden;
  }

  progress[value].progress-danger {
    --color-value: var(--color-danger);
  }

  progress[value].progress-success {
    --color-value: var(--color-success);
  }

  progress[value].progress-warning {
    --color-value: var(--color-warning);
  }

  progress[value]::-webkit-progress-bar {
    background-color: var(--control-color-border);
  }

  progress[value]::-webkit-progress-value {
    background-color: var(--color-value);
  }
}

progress[value].is-block {
  width: 100%;
  display: block;
}

button.btn-fab {
  background-color: var(--color-primary);
  cursor: pointer;
  z-index: 1;
  color: #fff;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: background-color .3s, box-shadow .3s;
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  box-shadow: 1px 1px 5px #00000080;
}

button.btn-fab:hover {
  background-color: var(--color-alternative);
  box-shadow: 1px 1px 6px #0009;
}

h1, h2, h3, h4, p {
  margin-top: .25rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5, h6 {
  font-size: 1rem;
}

h1, h2, h3 {
  font-weight: bold;
}

h4, h5, h6 {
  font-weight: normal;
}

h5, h6, .text-muted {
  color: var(--color-muted);
}

.text-warning {
  color: var(--color-warning);
}

.text-danger {
  color: var(--color-danger);
}

.text-success {
  color: var(--color-success);
}

.text-center {
  text-align: center;
}

pre, code, kbd {
  font-family: monospace;
}

pre {
  margin: 0;
  overflow: auto;
}

pre.with-wrap {
  white-space: pre-wrap;
}

.label {
  background: var(--color-information-bg);
  border: 1px solid var(--color-information);
  color: var(--color-information);
  white-space: nowrap;
  padding: .25rem .5rem;
  font-size: .75rem;
}

hr {
  background: var(--color-primary);
  border: 0;
  height: 1px;
}

.header-section {
  margin: 1rem 0;
}

.example-grid .grid > div {
  border: 1px solid var(--control-color-border);
  background: var(--control-color-background-normal);
  padding: 1rem;
}

footer.container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
/*# sourceMappingURL=index.ed888760.css.map */
