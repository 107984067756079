/* Scroll to Top */

button.btn-fab {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;

  border: 0;
  background-color: var(--color-primary);
  box-shadow: 1px 1px 5px rgba(#000, 0.5);

  transition:
    0.3s background-color,
    0.3s box-shadow;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  color: #fff;

  &:hover {
    background-color: var(--color-alternative);
    box-shadow: 1px 1px 6px rgba(#000, 0.6);
  }
}
