.noselect {
  user-select: none;
  cursor: default;
}

.pull-right {
  float: right;
}

.hide {
  display: none;
}

.img-circle {
  border-radius: 50%;
}

[hidden] {
  display: none !important;
}
