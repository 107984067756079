$container-width-normal: $breakpoint-desktop;
$container-width-narrow: $width-narrow;
$container-width-fluid: 100%;
$container-padding: 1rem;

.container {
  --container-width: #{$container-width-normal};
  --container-padding: #{$container-padding};
  width: min(var(--container-width), 100% - var(--container-padding));

  margin-left: auto;
  margin-right: auto;

  &.container-fluid {
    --container-width: #{$container-width-fluid};
  }

  &.container-narrow {
    --container-width: #{$container-width-narrow};
  }

  &.container--no-padding {
    --container-padding: 0px;
  }
}

.grid {
  display: grid;
  grid-gap: 1rem;

  grid-auto-rows: auto;

  &.grid--no-gap {
    grid-gap: 0;
  }

  @each $key, $columns in $grid-sizes {
    &.grid-mobile--#{$key} {
      grid-template-columns: $columns;
    }
  }

  @media screen and (min-width: $breakpoint-mobile) {
    @each $key, $columns in $grid-sizes {
      &.grid-tablet--#{$key} {
        grid-template-columns: $columns;
      }
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    @each $key, $columns in $grid-sizes {
      &.grid--#{$key} {
        grid-template-columns: $columns;
      }
    }
  }

  @media screen and (max-width: ($breakpoint-mobile - 1px)) {
    .hidden-mobile {
      display: none;
    }
  }

  @media screen and (min-width: $breakpoint-mobile) and (max-width: ($breakpoint-desktop - 1px)) {
    .hidden-tablet {
      display: none;
    }
  }

  @media screen and (min-width: $breakpoint-desktop) {
    .hidden-desktop {
      display: none;
    }
  }
}
